var config = ({
  firebase: {
    apiKey: "AIzaSyCe0wnib4D1D38n8vW_ckCVTs55UWpw9k4",
    authDomain: "biptt-free-dev-c236c.firebaseapp.com",
    projectId: "biptt-free-dev-c236c",
    databaseURL: "https://biptt-free-dev-c236c.firebaseio.com",
    storageBucket: "biptt-free-dev-c236c.appspot.com",
    messagingSenderId: "678982838468",
    appId: "1:678982838468:web:a1f17dc77b85f4d5c0c191",
    measurementId: "G-4K9PBB4GTM",
    vapidKey: "BPnBSA1yLqrjqQqBBeOcGUvUR62-8mywtH1_OR6oVQ08llv7"
  }
})

export default config;
